import axios from 'axios';

// Cache for user info to avoid repeating same data in every log
let userInfoCache = {
  location: null,
  email: null,
  phone: null,
  lastUpdated: null
};

class Logger {
  constructor() {
    this.logs = [];
    this.batchSize = 10;
    this.flushInterval = 5000; // 5 seconds
    this.apiUrl = ''; // Will be set later
    this.sessionId = this.initializeSessionId();
    
    // Add detailed environment debugging
    console.log("============= LOGGER INITIALIZATION =============");
    console.log('Environment Variables:');
    console.log('- NODE_ENV:', process.env.NODE_ENV);
    console.log('- REACT_APP_ENV:', process.env.REACT_APP_ENV);
    
    this.isDevelopment = process.env.NODE_ENV === 'development' || 
                         process.env.NODE_ENV === 'dev' || 
                         process.env.NODE_ENV === 'local' ||
                         process.env.REACT_APP_ENV === 'local';
    
    console.log('Logger Mode:', this.isDevelopment ? 'DEVELOPMENT' : 'PRODUCTION');
    console.log("=================================================");
    
    // Only start flush interval in production
    if (!this.isDevelopment) {
      setInterval(() => this.flush(), this.flushInterval);
    } else {
      // In development mode, send a test log to verify logger is working
      console.log('Logger initialized in development mode - logs will be output to console only');
      this.info('Logger initialized', { mode: 'development' });
    }
  }

  setApiUrl(url) {
    if (!this.isDevelopment) {
      this.apiUrl = url;
    }
  }

  generateId() {
    return `${Date.now()}-${Math.random().toString(36).substr(2, 9)}`;
  }

  initializeSessionId() {
    // Try to get existing session ID
    let sessionId = localStorage.getItem('loggerSessionId');
    
    // If no session ID exists or it's older than 30 minutes, create a new one
    const lastActivity = localStorage.getItem('loggerLastActivity');
    const now = Date.now();
    
    if (!sessionId || !lastActivity || (now - parseInt(lastActivity)) > 30 * 60 * 1000) {
      sessionId = this.generateId();
      localStorage.setItem('loggerSessionId', sessionId);
    }
    
    // Update last activity
    localStorage.setItem('loggerLastActivity', now.toString());
    
    // Set up activity monitor to update last activity time
    if (typeof window !== 'undefined') {
      window.addEventListener('click', () => this.updateLastActivity());
      window.addEventListener('keypress', () => this.updateLastActivity());
      window.addEventListener('scroll', () => this.updateLastActivity());
      window.addEventListener('mousemove', () => this.updateLastActivity());
    }
    
    return sessionId;
  }

  updateLastActivity() {
    localStorage.setItem('loggerLastActivity', Date.now().toString());
  }

  // Add a method to include user context information
  getUserInfo() {
    try {
      // Check if we have fresh cached data (less than 5 minutes old)
      const now = Date.now();
      if (userInfoCache.lastUpdated && (now - userInfoCache.lastUpdated) < 300000) {
        return { ...userInfoCache };
      }

      // Try to get location from localStorage - this is the only reliable info from localStorage
      const location = localStorage.getItem('selectedLocation');
      
      // We don't try to get email/phone from localStorage anymore since they're not stored there
      // They will be added via updateUserContext when a user logs in via AuthContext
      
      // Update cache with what we know
      userInfoCache = {
        location,
        email: userInfoCache.email || null,
        phone: userInfoCache.phone || null,
        userId: userInfoCache.userId || null,
        lastUpdated: now
      };
      
      return { ...userInfoCache };
    } catch (error) {
      console.error('Error getting user info for logs:', error);
      return {};
    }
  }

  async log(level, message, data = {}) {
    // Get user info for both development and production
    const userInfo = this.getUserInfo();
    const enhancedData = {
      ...data,
      userContext: {
        ...userInfo
      },
      sessionId: this.sessionId
    };

    // Create the full log entry that would be sent to Cosmos DB
    const logEntry = {
      id: this.generateId(),
      timestamp: new Date().toISOString(),
      level,
      message,
      ...enhancedData
    };

    // In development, log the full entry to console
    if (this.isDevelopment) {
      switch (level) {
        case 'error':
          console.error(`[${level.toUpperCase()}] ${message}`, logEntry);
          break;
        case 'warn':
          console.warn(`[${level.toUpperCase()}] ${message}`, logEntry);
          break;
        case 'debug':
          console.debug(`[${level.toUpperCase()}] ${message}`, logEntry);
          break;
        default:
          console.log(`[${level.toUpperCase()}] ${message}`, logEntry);
      }
      return;
    }

    // Production logging - add to queue
    this.logs.push(logEntry);

    if (this.logs.length >= this.batchSize) {
      await this.flush();
    }
  }

  // New method to explicitly set user information (to be called after login/location change)
  updateUserContext(userData = {}) {
    const { location, email, phone } = userData;
    
    userInfoCache = {
      location: location || userInfoCache.location,
      email: email || userInfoCache.email,
      phone: phone || userInfoCache.phone,
      lastUpdated: Date.now()
    };
  }

  info(message, data = {}) {
    return this.log('info', message, data);
  }

  error(message, data = {}) {
    return this.log('error', message, data);
  }

  warn(message, data = {}) {
    return this.log('warn', message, data);
  }

  debug(message, data = {}) {
    return this.log('debug', message, data);
  }

  async flush() {
    // Don't attempt to flush in development
    if (this.isDevelopment || this.logs.length === 0) {
      return;
    }

    const logsToSend = [...this.logs];
    this.logs = [];

    try {
      const endpoint = this.apiUrl ? `${this.apiUrl}/api/logs` : '/api/logs';
      await axios.post(endpoint, { logs: logsToSend });
    } catch (error) {
      console.error('Failed to send logs:', error);
      // Put the logs back in the queue
      this.logs = [...logsToSend, ...this.logs];
    }
  }
}

const logger = new Logger();
export default logger; 