import React, { createContext, useState, useEffect } from 'react';
import logger from '../services/logger';

export const LocationContext = createContext();

export const LocationProvider = ({ children }) => {
  const [location, setLocation] = useState(() => {
    return localStorage.getItem('selectedLocation') || null;
  });

  useEffect(() => {
    if (location) {
      localStorage.setItem('selectedLocation', location);
      
      // Log location change
      logger.info('Location changed', { location });
      
      // Update location in logger context
      logger.updateUserContext({ location });
    } else {
      localStorage.removeItem('selectedLocation');
    }
  }, [location]);

  return (
    <LocationContext.Provider value={{ location, setLocation }}>
      {children}
    </LocationContext.Provider>
  );
};