import React, { createContext, useState, useEffect, useContext } from "react";
import logger from "../../services/logger";
import { LocationContext } from "../../contexts/LocationContext";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState(null);
  const { location } = useContext(LocationContext);

  // Custom setter that also updates logger context
  const updateAuth = (userData) => {
    setAuth(userData);
    
    // If user logged in, update logger with their information
    if (userData) {
      logger.updateUserContext({
        email: userData.email,
        phone: userData.phoneNumber,
        location: location,
        userId: userData.uid
      });
      
      logger.info('User authenticated', {
        userId: userData.uid,
        hasEmail: !!userData.email,
        hasPhone: !!userData.phoneNumber
      });
    } else {
      // Clear user context on logout
      logger.updateUserContext({
        email: null,
        phone: null,
        userId: null
      });
    }
  };

  // Update logger whenever location changes
  useEffect(() => {
    if (auth && location) {
      logger.updateUserContext({
        location
      });
    }
  }, [location, auth]);

  return (
    <AuthContext.Provider value={{ auth, setAuth: updateAuth }}>
      {children}
    </AuthContext.Provider>
  );
};
