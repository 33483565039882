let apiUrl = null;

export const setApiUrl = (url) => {
  apiUrl = url;
};

export const getApiUrl = () => {
  if (!apiUrl) {
    throw new Error('API URL not configured');
  }
  return apiUrl;
}; 